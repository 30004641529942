<template>
  <div ref="footerWidget">
    <div v-if="getMeetingSectionContent" class="footer-calendly section">
      <h2 class="title" data-aos="fade-up">{{ getMeetingSectionContent.title }}</h2>
      <p class="subtitle" data-aos="fade-up">{{ getMeetingSectionContent.subtitle }}</p>
      <div v-if="showWidget" class="calendly-wrapper" data-aos="fade-up">
        <!-- <AppCalendlyWidget v-show="isCalendlyLoaded" @calendly-loaded="calendlyLoaded" /> -->
        <AppLoader v-show="!isCalendlyLoaded" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppLoader from '@/components/common/AppLoader'
import { isElementInViewport } from '@/utils/is-element-in-viewport'

export default {
  name: 'AppFooterCalendly',
  components: { AppLoader },
  data() {
    return {
      showWidget: false,
      widgetRef: null,
      isCalendlyLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['getMeetingSectionContent']),
  },
  mounted() {
    this.$nextTick(() => {
      this.widgetRef = this.$refs?.footerWidget

      window.addEventListener('scroll', this.handleScroll)
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (isElementInViewport(this.widgetRef, -100)) {
        this.showWidget = true
        window.removeEventListener('scroll', this.handleScroll)
      }
    },
    calendlyLoaded() {
      this.isCalendlyLoaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppFooterCalendly';
</style>
