<template>
  <footer id="contact" class="footer">
    <div
      v-if="config.footerWithBackground"
      class="background"
      :class="{ '--without-calendly': !config.footerWithCalendly }"
    >
      <div class="decoration-cylinder"></div>
    </div>
    <AppFooterCalendly v-if="config.footerWithCalendly" />
    <AppFooterContact v-if="config.footerWithForm" :with-title="config.formWithTitle" />
    <AppFooterBottom />
  </footer>
</template>

<script>
import AppFooterBottom from './AppFooterBottom.vue'
import AppFooterContact from './AppFooterContact.vue'
import AppFooterCalendly from './AppFooterCalendly.vue'

export default {
  name: 'AppFooter',
  components: { AppFooterContact, AppFooterBottom, AppFooterCalendly },
  computed: {
    config() {
      return this.$route.meta
    },
  },
  mounted() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const contactParam = urlParams.get('contact')
    if (contactParam !== null) {
      const contactEl = document.querySelector('#contact')
      if (!contactEl) return
      contactEl.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      })
      urlParams.delete('contact')
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      history.replaceState({}, document.title, newUrl)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppFooter';
</style>
